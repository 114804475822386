<template>
	<div class="add-element">
		<div
			v-for="(element,key) in filteredElements"
			:key="`add-${key}`"
			v-qa="`builder-addelements-element-${element.name}`"
			class="add-element__element"
			draggable
			@dragstart="onDragStart($event, key)"
			@dragend="onDragEnd"
		>
			<div class="add-element__icon">
				<ZyroSvg
					:name="element.icon"
				/>
			</div>
			<div
				class="z-body-small"
				v-text="element.name"
			/>
		</div>
	</div>
</template>

<script>
import { nanoid } from 'nanoid';
import {
	mapActions,
	mapGetters,
	mapState,
} from 'vuex';

import EventLogApi from '@/api/EventLogApi';
import i18n from '@/i18n/setup';
import { filterObject } from '@/utils/object';

/**
 * Kept inside vue file as translations are loaded before so they default to english
 * Passing keys is a good idea, but here we have content, so translation would also have to be done in GridText
 * Leaving this here now until a better solution comes up
 */
const elements = {
	text: {
		name: i18n.t('common.text'),
		icon: 'text',
		width: 4,
		height: 3,
		content: {
			type: 'GridTextBox',
			content: `<p class="h-3">${i18n.t('builder.elements.defaultTextElementText')}</p>`,
			settings: {
				styles: {
					'm-element-margin': '0 0 16px 0',
					text: 'left',
					align: 'flex-start',
					justify: 'flex-start',
				},
			},
		},
	},
	button: {
		name: i18n.t('common.button'),
		icon: 'button',
		width: 3,
		height: 2,
		content: {
			type: 'GridButton',
			content: 'Button',
			settings: {
				href: '#',
				type: 'primary',
				styles: {
					'm-element-margin': '0 0 16px 0',
					align: 'center',
					justify: 'center',
				},
			},
		},
	},
	stripeButton: {
		name: i18n.t('common.stripeCheckout'),
		icon: 'stripe-blurple',
		width: 4,
		height: 2,
		content: {
			type: 'GridStripeButton',
			content: 'Buy now',
			paymentType: 'payment',
			settings: {
				href: '#',
				type: 'primary',
				styles: {
					'm-element-margin': '0 0 16px 0',
					align: 'center',
					justify: 'center',
				},
			},
		},
	},
	image: {
		name: i18n.t('common.image'),
		icon: 'image',
		width: 4,
		height: 6,
		content: {
			type: 'GridImage',
			settings: {
				alt: '',
				image: 'https://zrassets.s3.eu-north-1.amazonaws.com/bed-and-breakfast/images/amenities.jpg',
				clickAction: 'lightbox',
				styles: {
					'm-element-margin': '0 0 16px 0',
					align: 'center',
					justify: 'center',
				},
			},
		},
	},
	gallery: {
		name: i18n.t('common.gallery'),
		icon: 'gallery',
		width: 9,
		height: 2,
		content: {
			type: 'GridGallery',
			settings: {
				layout: 'grid',
				imageClickAction: 'lightbox',
				styles: {
					'column-gap': '16px',
					'column-count': '4',
					'm-element-margin': '0 0 16px 0',
				},
			},
			images: [
				'https://assets.zyrosite.com//gallery-assets/image-Yanb7y92okhNeLgL.png',
				'https://assets.zyrosite.com//gallery-assets/image-YX452yb11eIkBgzn.png',
				'https://assets.zyrosite.com//gallery-assets/image-A85NDw46r5I5wqjp.png',
				'https://assets.zyrosite.com//gallery-assets/image-YZ95MVban0SqegkB.png',
				'https://assets.zyrosite.com//gallery-assets/image-AMqG2b4XWMFGQ5Q4.png',
				'https://assets.zyrosite.com//gallery-assets/image-AzG9Oe7kPXSW6OVN.png',
				'https://assets.zyrosite.com//gallery-assets/image-YD09r64jyNCnGRy6.png',
				'https://assets.zyrosite.com//gallery-assets/image-mv0eLD79xZCjoNwE.png',
			],
		},
	},
	video: {
		name: i18n.t('common.video'),
		icon: 'video',
		width: 5,
		height: 5,
		content: {
			type: 'GridVideo',
			settings: {
				src: 'https://www.youtube.com/embed/c8aFcHFu8QM',
				styles: {
					'm-element-margin': '0 0 16px 0',
					align: 'center',
					justify: 'center',
				},
			},
		},
	},
	map: {
		name: i18n.t('common.map'),
		icon: 'map',
		width: 5,
		height: 5,
		content: {
			type: 'GridMap',
			settings: {
				'm-element-margin': '0 0 16px 0',
				src: 'https://maps.google.com/maps?q=697 Hilltop Street, Springfield, MA, USA&t=&z=13&ie=UTF8&iwloc=&output=embed',
				styles: {
					align: 'center',
					justify: 'center',
				},
			},
		},
	},
	instagramFeed: {
		name: i18n.t('common.instagramFeed'),
		icon: 'instagram',
		width: 10,
		height: 5,
		content: {
			type: 'GridInstagramFeed',
			settings: {
				username: null,
				'item-count': 8,
				styles: {
					'm-element-margin': '0 0 16px 0',
					'items-per-row': 4,
					'm-items-per-row': 3,
					'item-gap': '16px',
					'm-item-gap': '8px',
					align: 'center',
					justify: 'flex-start',
				},
			},
		},
	},
	form: {
		name: i18n.t('common.contactForm'),
		icon: 'contact',
		width: 5,
		height: 5,
		content: {
			type: 'GridForm',
			formId: 'contactForm',
			settings: {
				successMessage: 'Thank You!',
				schema: [
					{
						name: 'name',
						id: 'name',
						svg: 'user',
						children: [
							{
								name: 'firstName',
								placeholder: 'Your name',
								inputLabel: 'Name',
								type: 'GridInput',
								validation: [['required']],
								'validation-messages': { required: 'This field is required' },
							},
							{
								name: 'lastName',
								placeholder: 'Your last name',
								inputLabel: 'Last name',
								validation: [['optional']],
								type: 'GridInput',
								'validation-messages': { required: 'This field is required' },
							},
						],
					},
					{
						id: 'email',
						name: 'email',
						inputLabel: 'Your email',
						validation: [
							['bail'],
							['email'],
							['required'],
						],
						'validation-messages': {
							required: 'This field is required',
							email: 'Please enter a valid email address',
						},
						placeholder: 'Your email address',
						type: 'GridInput',
						svg: 'mail',
					},
					{
						id: 'message',
						tag: 'textarea',
						inputLabel: 'Message',
						name: 'content',
						validation: [['required']],
						'validation-messages': { required: 'This field is required' },
						placeholder: 'Enter your message',
						type: 'GridInput',
						svg: 'content',
					},
				],
				theme: 'light',
				styles: {
					'm-element-margin': '0 0 16px 0',
					justify: 'center',
					formSpacing: '22px 10px',
				},
			},
			submitButtonData: {
				type: 'GridButton',
				content: 'Submit',
				settings: {
					type: 'primary',
					isFormButton: true,
					styles: {
						align: 'center',
						justify: 'center',
						position: '8/8/9/10',
					},
				},
			},
			innerBackground: {
				current: '',
				color: '#ffffff',
				image: '',
			},
		},
	},
	subscribeForm: {
		name: i18n.t('common.subscribe'),
		icon: 'subscribe',
		width: 5,
		height: 5,
		content: {
			type: 'GridForm',
			formId: 'subscribeForm',
			settings: {
				type: 'singleField',
				successMessage: 'Thank you!',
				schema: [
					{
						svg: 'mail',
						id: 'email',
						name: 'email',
						inputLabel: 'Email address',
						validation: [
							['required'],
							['bail'],
							['email'],
						],
						'validation-messages': { required: 'Email is required' },
						placeholder: 'Your email address',
						type: 'GridInput',
					},
				],
				theme: 'light',
				styles: {
					'm-element-margin': '0 0 16px 0',
					align: 'space-between',
					text: 'left',
					justify: 'center',
					formSpacing: '22px 10px',
				},
			},
			submitButtonData: {
				type: 'GridButton',
				content: 'Submit',
				settings: {
					type: 'primary',
					isFormButton: true,
					styles: {
						align: 'center',
						position: '8/8/9/10',
					},
				},
			},
			innerBackground: {
				current: '',
				color: '#ffffff',
				image: '',
			},
		},
	},
	socialIcons: {
		name: i18n.t('common.socialIcons'),
		icon: 'socialLinks',
		width: 2,
		height: 1,
		content: {
			type: 'GridSocialIcons',
			settings: {
				useBrandColors: false,
				styles: {
					'm-element-margin': '0 0 16px 0',
					align: '',
					justify: 'center',
					'space-between-icons': '20px',
					'icon-direction': 'row',
					'icon-color': '#000000',
					'icon-color-hover': '#3a3a3a',
					'icon-spacing': 'space-around',
					'icon-size': '20px',
				},
			},
			links: [
				{
					link: 'https://twitter.com/zyrocom',
					icon: 'twitter',
					svg: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M21.5331 7.11165C21.5483 7.32484 21.5483 7.53807 21.5483 7.75126C21.5483 14.2538 16.5991 21.7462 7.55333 21.7462C4.7665 21.7462 2.17768 20.9391 0 19.5381C0.395955 19.5838 0.776628 19.599 1.18782 19.599C3.48728 19.599 5.60407 18.8224 7.29444 17.4975C5.13199 17.4518 3.31979 16.0356 2.69542 14.0863C3.00001 14.132 3.30456 14.1624 3.62439 14.1624C4.066 14.1624 4.50766 14.1015 4.9188 13.9949C2.66499 13.538 0.974582 11.5584 0.974582 9.1675V9.10661C1.62938 9.47209 2.39087 9.70052 3.19792 9.73094C1.87304 8.84767 1.00505 7.34007 1.00505 5.63447C1.00505 4.72078 1.24866 3.88321 1.67508 3.15224C4.09642 6.13702 7.73605 8.08623 11.8172 8.29946C11.7411 7.93398 11.6954 7.55331 11.6954 7.17259C11.6954 4.46189 13.8883 2.25378 16.6142 2.25378C18.0304 2.25378 19.3096 2.84769 20.2081 3.80709C21.3198 3.5939 22.3858 3.18271 23.33 2.61927C22.9644 3.76143 22.1878 4.72082 21.1675 5.32992C22.1574 5.22337 23.1168 4.9492 24 4.56853C23.3301 5.54311 22.4924 6.4111 21.5331 7.11165Z" fill="currentColor"/></svg>',
				},
				{
					link: 'https://www.facebook.com/ZyroCOM/',
					icon: 'facebook',
					svg: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M24 12.0726C24 5.44354 18.629 0.0725708 12 0.0725708C5.37097 0.0725708 0 5.44354 0 12.0726C0 18.0619 4.38823 23.0264 10.125 23.9274V15.5414H7.07661V12.0726H10.125V9.4287C10.125 6.42144 11.9153 4.76031 14.6574 4.76031C15.9706 4.76031 17.3439 4.99451 17.3439 4.99451V7.94612H15.8303C14.34 7.94612 13.875 8.87128 13.875 9.82015V12.0726H17.2031L16.6708 15.5414H13.875V23.9274C19.6118 23.0264 24 18.0619 24 12.0726Z" fill="currentColor"/></svg>',
				},
				{
					link: 'https://www.instagram.com/zyro_com/',
					icon: 'instagram',
					svg: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.0027 5.84808C8.59743 5.84808 5.85075 8.59477 5.85075 12C5.85075 15.4053 8.59743 18.1519 12.0027 18.1519C15.4079 18.1519 18.1546 15.4053 18.1546 12C18.1546 8.59477 15.4079 5.84808 12.0027 5.84808ZM12.0027 15.9996C9.80212 15.9996 8.00312 14.2059 8.00312 12C8.00312 9.7941 9.79677 8.00046 12.0027 8.00046C14.2086 8.00046 16.0022 9.7941 16.0022 12C16.0022 14.2059 14.2032 15.9996 12.0027 15.9996ZM19.8412 5.59644C19.8412 6.39421 19.1987 7.03135 18.4062 7.03135C17.6085 7.03135 16.9713 6.38885 16.9713 5.59644C16.9713 4.80402 17.6138 4.16153 18.4062 4.16153C19.1987 4.16153 19.8412 4.80402 19.8412 5.59644ZM23.9157 7.05277C23.8247 5.13063 23.3856 3.42801 21.9775 2.02522C20.5747 0.622429 18.8721 0.183388 16.9499 0.0870135C14.9689 -0.0254238 9.03112 -0.0254238 7.05008 0.0870135C5.1333 0.178034 3.43068 0.617075 2.02253 2.01986C0.614389 3.42265 0.180703 5.12527 0.0843279 7.04742C-0.0281093 9.02845 -0.0281093 14.9662 0.0843279 16.9472C0.175349 18.8694 0.614389 20.572 2.02253 21.9748C3.43068 23.3776 5.12794 23.8166 7.05008 23.913C9.03112 24.0254 14.9689 24.0254 16.9499 23.913C18.8721 23.822 20.5747 23.3829 21.9775 21.9748C23.3803 20.572 23.8193 18.8694 23.9157 16.9472C24.0281 14.9662 24.0281 9.03381 23.9157 7.05277ZM21.3564 19.0728C20.9388 20.1223 20.1303 20.9307 19.0755 21.3537C17.496 21.9802 13.7481 21.8356 12.0027 21.8356C10.2572 21.8356 6.50396 21.9748 4.92984 21.3537C3.88042 20.9361 3.07195 20.1276 2.64897 19.0728C2.02253 17.4934 2.16709 13.7455 2.16709 12C2.16709 10.2546 2.02789 6.50129 2.64897 4.92717C3.06659 3.87776 3.87507 3.06928 4.92984 2.6463C6.50931 2.01986 10.2572 2.16443 12.0027 2.16443C13.7481 2.16443 17.5014 2.02522 19.0755 2.6463C20.1249 3.06392 20.9334 3.8724 21.3564 4.92717C21.9828 6.50665 21.8383 10.2546 21.8383 12C21.8383 13.7455 21.9828 17.4987 21.3564 19.0728Z" fill="currentColor"/></svg>',
				},
			],
		},
	},
};

export default {
	name: 'AddElementList',
	data() {
		return { isEmptyPageDrag: false };
	},
	computed: {
		...mapState('user', ['user']),
		...mapState(['website']),
		...mapGetters('user', ['isZyroUser']),
		...mapGetters('pages', [
			'isEmptyPageBlockShown',
			'currentPage',
		]),
		filteredElements() {
			if (this.isZyroUser) {
				return elements;
			}

			return filterObject(elements, ({ key }) => key !== 'stripeButton');
		},
	},
	methods: {
		...mapActions(['removeBlock']),
		onDragStart(event, key) {
			if (this.isEmptyPageBlockShown) {
				this.isEmptyPageDrag = true;

				this.$store.dispatch('addBlock', { previousBlockId: null });
			}

			const {
				content,
				width,
				height,
			} = this.filteredElements[key];

			event.dataTransfer.setData('element', key);
			event.dataTransfer.setData('content', JSON.stringify(content));
			event.dataTransfer.setData('elementId', nanoid());
			event.dataTransfer.setData('width', width);
			event.dataTransfer.setData('height', height);
			event.dataTransfer.setData('eventData', JSON.stringify({
				from: 'addElement',
				type: content.type,
			}));

			if (key === 'instagramFeed') {
				EventLogApi.logEvent({ eventName: 'instagram.addInstagram' });
			}
		},
		onDragEnd() {
			if (this.isEmptyPageDrag) {
				this.handleEmptyPageBlock();
				this.isEmptyPageDrag = false;
			}
		},
		handleEmptyPageBlock() {
			const blockId = this.currentPage.blocks[0];
			const hasElements = this.website.blocks[blockId].components.length > 0;

			if (!hasElements) {
				this.removeBlock({ blockId });
			}
		},
	},
};
</script>

<style scoped lang="scss">
.add-element {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 16px;
	color: $grey-800;
	text-align: center;

	@media screen and (max-height: 800px) {
		grid-template-columns: 1fr 1fr 1fr;
	}

	&__info {
		display: block;
		padding: 12px 24px;
		margin-bottom: 12px;
		text-align: center;
		background: $grey-100;
	}

	&__icon {
		display: flex;
		width: 100%;
		padding: 16px;
		margin-bottom: 8px;
		border: 1px solid $grey-300;
		transition: 300ms border-color;

		svg {
			width: 100%;
			height: 48px;
		}
	}

	&__element {
		cursor: move;

		&:hover .add-element__icon {
			border-color: $dark;
		}
	}
}
</style>
