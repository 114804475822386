<template>
	<ZyroDrawer class="add-element-drawer">
		<p class="z-h5 add-element-drawer__title">
			{{ $t('common.addElements') }}
		</p>
		<AddElementList />
	</ZyroDrawer>
</template>

<script>
import AddElementList from '@/components/builder-controls/add-element/AddElementList.vue';

export default { components: { AddElementList } };
</script>

<style lang="scss" scoped>
.add-element-drawer {
	padding: 24px;

	&__title {
		margin-bottom: 22px;
	}
}
</style>
